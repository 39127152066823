import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check, CheckCircle } from 'react-feather'
import MadeInUSA from '../assets/icons/madeinusa'
import Button from './atoms/Button'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { navigate } from 'gatsby'

const WhyUs = () => {
  const [claimingInProgress, setClaimingInProgress] = useState(false)

  const [offer, setOffer] = useLocalStorage('offers', {})

  const claimOffer = () => {
    setOffer({ offer: '$50 OFF Tesla Wall Charger Installation', expires: '' })
    setClaimingInProgress(true)
    setTimeout(() => {
      navigate('/contact/')
    }, 1000)
  }

  return (
    <StaticQuery
      query={graphql`
        query WhyUsListQuery {
          site {
            siteMetadata {
              whyUs
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            <div className="rounded-2xl mb-2 shadow bg-white shadow-md">
              <div class="px-8 pt-6">
                <h4 class="text-brand-500 font-bold text-xl md:text-2xl font-display">
                  Why Us?
                </h4>
              </div>
              <ul className="pb-8 pt-6 px-8 space-y-6 text-gray-600">
                <li className="flex items-start">
                  {' '}
                  <Check
                    size={24}
                    fontWeight="900"
                    strokeWidth={3}
                    className="bg-brand-100 h-7 w-7 p-1 rounded-full text-brand-500 shrink-0"
                  />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-brand-500 block">
                      No Hidden Costs, Free Quotes
                    </span>
                    <p>Transparent, Up Front Pricing Without Surprises</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Check
                    size={24}
                    fontWeight="900"
                    strokeWidth={3}
                    className="bg-brand-100 h-7 w-7 p-1 rounded-full text-brand-500 shrink-0"
                  />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-brand-500 block">
                      Family-Owned &amp; Operated
                    </span>

                    <p>Sacramento-Based Contractors</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Check
                    size={24}
                    fontWeight="900"
                    strokeWidth={3}
                    className="bg-brand-100 h-7 w-7 p-1 rounded-full text-brand-500 shrink-0"
                  />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-brand-500 block">
                      Experienced
                    </span>

                    <p>10+ Years of Experience</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Check
                    size={24}
                    fontWeight="900"
                    strokeWidth={3}
                    className="bg-brand-100 h-7 w-7 p-1 rounded-full text-brand-500 shrink-0"
                  />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-brand-500 block">
                      Satisfaction Guarantee
                    </span>

                    <p>We Offer a Satisfaction Guarantee on All Work</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="rounded-2xl mb-2 shadow bg-orange-500 hidden">
              <div class="px-8 py-6 flex items-start space-x-4">
                <img
                  src="https://ironriverco.com/wp-content/uploads/home-remodeling-contractor-red-flags.jpg.webp"
                  className="w-16 h-16 rounded-full  shadow shrink-0"
                />
                <div>
                  <h3 class="text-white font-black text-lg md:text-xl font-display">
                    Talk to our stucco experts
                  </h3>
                  <p className="text-gray-100 font-display font-normal">
                    Your questions answered, free estimates, clear pricing
                  </p>
                  <Button
                    className="bg-white mt-4"
                    color="secondary"
                    to="/contact/"
                  >
                    Contact
                  </Button>
                </div>
              </div>
            </div>
            <div className="hidden rounded-2xl mb-2 shadow bg-white mt-4 border-4 border-brand-100">
              <div class="px-8 py-6 flex items-center space-x-4">
                <img
                  src="https://images.ctfassets.net/seqn6hyrh7p7/KhtI1D3NxmJaQZ1SdiXul/e041fdecc73b85da180569819df93db5/kindpng_4658234.png"
                  className="w-1/3"
                />
                <div>
                  <h4 class="text-brand-500 font-black text-xl md:text-3xl font-display">
                    $50 OFF
                  </h4>
                  <p className="text-brand-500 font-display font-semibold text-lg ">
                    Tesla Wall Charger Installation
                  </p>
                  {/* <Link to="/">Learn More</Link> */}
                  <Button className="mt-4" onClick={() => claimOffer()}>
                    {claimingInProgress ? (
                      <svg
                        class="animate-spin ml-3 mr-3 h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      'Claim'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default WhyUs
